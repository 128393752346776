import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b6eb713 = () => interopDefault(import('../../src/manager/pages/groups/index.vue' /* webpackChunkName: "pages/groups/index" */))
const _f94aa428 = () => interopDefault(import('../../src/manager/pages/packages/index.vue' /* webpackChunkName: "pages/packages/index" */))
const _da5d3826 = () => interopDefault(import('../../src/manager/pages/service-desk.vue' /* webpackChunkName: "pages/service-desk" */))
const _125cba80 = () => interopDefault(import('../../src/manager/pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _6e0c1dfe = () => interopDefault(import('../../src/manager/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _1a1c760b = () => interopDefault(import('../../src/manager/pages/groups/create.vue' /* webpackChunkName: "pages/groups/create" */))
const _c1bfdf04 = () => interopDefault(import('../../src/manager/pages/groups/_id/index.vue' /* webpackChunkName: "pages/groups/_id/index" */))
const _5012f2d7 = () => interopDefault(import('../../src/manager/pages/packages/_id/index.vue' /* webpackChunkName: "pages/packages/_id/index" */))
const _3ad9aa2c = () => interopDefault(import('../../src/manager/pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))
const _4c8de9e4 = () => interopDefault(import('../../src/manager/pages/groups/_id/edit.vue' /* webpackChunkName: "pages/groups/_id/edit" */))
const _25006034 = () => interopDefault(import('../../src/manager/pages/groups/_id/packages/index.vue' /* webpackChunkName: "pages/groups/_id/packages/index" */))
const _4c4c2b43 = () => interopDefault(import('../../src/manager/pages/groups/_id/subgroups/index.vue' /* webpackChunkName: "pages/groups/_id/subgroups/index" */))
const _51a09a37 = () => interopDefault(import('../../src/manager/pages/groups/_id/users/index.vue' /* webpackChunkName: "pages/groups/_id/users/index" */))
const _5771f9c6 = () => interopDefault(import('../../src/manager/pages/groups/_id/users/add.vue' /* webpackChunkName: "pages/groups/_id/users/add" */))
const _449e330d = () => interopDefault(import('../../src/manager/pages/groups/_id/users/_userId/index.vue' /* webpackChunkName: "pages/groups/_id/users/_userId/index" */))
const _143cf3c6 = () => interopDefault(import('../../src/manager/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/groups",
    component: _7b6eb713,
    name: "groups"
  }, {
    path: "/packages",
    component: _f94aa428,
    name: "packages"
  }, {
    path: "/service-desk",
    component: _da5d3826,
    name: "service-desk"
  }, {
    path: "/auth/callback",
    component: _125cba80,
    name: "auth-callback"
  }, {
    path: "/auth/login",
    component: _6e0c1dfe,
    name: "auth-login"
  }, {
    path: "/groups/create",
    component: _1a1c760b,
    name: "groups-create"
  }, {
    path: "/groups/:id",
    component: _c1bfdf04,
    name: "groups-id"
  }, {
    path: "/packages/:id",
    component: _5012f2d7,
    name: "packages-id"
  }, {
    path: "/users/:id",
    component: _3ad9aa2c,
    name: "users-id"
  }, {
    path: "/groups/:id/edit",
    component: _4c8de9e4,
    name: "groups-id-edit"
  }, {
    path: "/groups/:id/packages",
    component: _25006034,
    name: "groups-id-packages"
  }, {
    path: "/groups/:id/subgroups",
    component: _4c4c2b43,
    name: "groups-id-subgroups"
  }, {
    path: "/groups/:id/users",
    component: _51a09a37,
    name: "groups-id-users"
  }, {
    path: "/groups/:id/users/add",
    component: _5771f9c6,
    name: "groups-id-users-add"
  }, {
    path: "/groups/:id/users/:userId",
    component: _449e330d,
    name: "groups-id-users-userId"
  }, {
    path: "/",
    component: _143cf3c6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

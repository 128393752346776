export const state = () => {
  return {
    permissions: [],
  };
};

export const mutations = {
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
};

<template>
  <defaultLayout :navigation-items="getNavigationItems()">
    <template slot="search">
      <manager-search />
    </template>
  </defaultLayout>
</template>

<script>
import Vue from 'vue';
import defaultLayout from '~shared/layouts/default.vue';
import gql from 'graphql-tag';
import ManagerSearch from '../components/ManagerSearch.vue';

export default Vue.extend({
  components: {
    ManagerSearch,
    defaultLayout,
  },
  data() {
    return {
      memberships: [],
    };
  },
  methods: {
    getNavigationItems() {
      return this.$settings.getNavigationItems(
        'appSettings.products.manager.navigation.items'
      );
    },
  },
  apollo: {
    memberships: {
      query: gql`
        query ($onlyShowAdminGroups: Boolean) {
          CurrentUserMemberships(onlyShowAdminGroups: $onlyShowAdminGroups) {
            groupId
          }
        }
      `,
      variables() {
        return {
          onlyShowAdminGroups: true,
        };
      },
      update(data) {
        if (data.CurrentUserMemberships) {
          return data.CurrentUserMemberships.map(
            (membership) => membership.groupId
          );
        }
        return [];
      },
    },
  },
});
</script>
